@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .leaflet-container {
    width: 100vw;
    height: 100vh;
  }
  .flex-center-fix {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  input:-webkit-autofill::selection {
    background: var(--primary-color);
    -webkit-text-fill-color: white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
